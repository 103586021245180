import React from "react"
import { graphql, Link } from "gatsby"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import Product from "../components/Product"

class Index extends React.Component {
  render() {
    const title = `Products`
    const description = `Engineered for Australia's extreme summers & winters. Heat or cool your whole home or a single room with our range of climate solutions. Smart choice cooling. Leading warranties. Easy installation. Leading health features. Made to last. Types: split system air conditioning, multi air conditioning, ducted air conditioning, cassette air conditioning, window air conditioning, gas ducted heating, evaporative cooling`
    const breadcrumbs = [
      {
        label: title,
        path: "/products/",
        active: true,
      },
    ]

    const items = []
    const products = this.props.data.allProductsYaml.edges
    products.forEach((product) => {
      items.push(
        <Product
          data={product}
          category={product.categories}
          key={product.node.id}
        />
      )
    })

    return (
      <Layout>
        <SEO title={title} description={description} />

        <Breadcrumbs items={breadcrumbs} />

        <div className="uk-section uk-section-secondary">
          <div className="uk-container">
            <div className="uk-grid uk-grid-small" uk-grid="">
              <aside className="uk-width-1-4@m">
                {/*<div
                  className="uk-background-muted uk-padding-small"
                  uk-sticky="media: @m; offset: 90; bottom: true;"
                >
                  <h5>Find your ideal system</h5>
                  <div className="category-links">
                    <p className="uk-text-bold uk-margin-small">System Type</p>
                    <ul className="uk-list uk-margin-remove">
                      <li>
                        <Link
                          to="/products/category/dual-function/"
                          className="uk-link-reset uk-flex uk-flex-middle"
                        >
                          <div className="checkbox uk-margin-small-right">
                            <span uk-icon="icon: check; ratio: 0.8"></span>
                          </div>
                          <span>Dual function</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/category/heating/"
                          className="uk-link-reset uk-flex uk-flex-middle"
                        >
                          <div className="checkbox uk-margin-small-right">
                            <span uk-icon="icon: check; ratio: 0.8"></span>
                          </div>
                          <span>Heating Only</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/category/cooling/"
                          className="uk-link-reset uk-flex uk-flex-middle"
                        >
                          <div className="checkbox uk-margin-small-right">
                            <span uk-icon="icon: check; ratio: 0.8"></span>
                          </div>
                          <span>Cooling Only</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products/category/cooling/"
                          className="uk-link-reset uk-flex uk-flex-middle"
                        >
                          <div className="checkbox uk-margin-small-right">
                            <span uk-icon="icon: check; ratio: 0.8"></span>
                          </div>
                          Cooling
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>*/}
              </aside>
              <main className="uk-width-2-4@m"> {/* Original size 3-4*/}
                <div
                  className="uk-grid uk-grid-small uk-child-width-1-3@s uk-text-center uk-grid-match"
                  uk-grid=""
                >
                  {items}
                </div>
              </main>
            </div>
          </div>
        </div>

        {/*<div className="uk-section uk-section-secondary uk-section-small uk-section-default">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{description} </small>
            </div>
          </div>
        </div>*/}
      </Layout>
    )
  }
}

export default Index

// Modified query with added filter, remove it to restore original
export const pageQuery = graphql`
  query IndexQuery {
    allProductsYaml(
      sort: {fields: sort, order: ASC}
      limit: 100
      filter: {categories: {in: ["cassette", "wallMounted", "MultiAirCon"]}, , title: {nin: "KSI Split System Air Conditioner"}}
    ) {
      edges {
        node {
          id
          title
          description_short
          categories
          variants {
            price
          }
          featured_image
          fields {
            slug
          }
        }
      }
    }
  }
`
